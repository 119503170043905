<template>
    <div class="contact">
        <div class="contact-title">{{getContactTitle ||  $t('CONTACT US')}}</div>
        <h1>{{ $t('CONTACTS') }}</h1>
        <image-component :path="channelSettings.content['channel-logo']" alt="" class="contact-logo"/>
        <div class="container">
            <div class="contact-text" v-html="getContactText || text"></div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import ImageComponent from "../components/base-components/ImageComponent";

    export default {
        name: "ContactScreen",
      components: {ImageComponent},
      data() {
            return {
                text: 'The CFL Live: 2019 International streaming video site is powered by Yare Media Group Inc.. Please note that this service is designed to service fans that are outside of major market regions serviced by TSN, ESPN and BT Sport. The service is NOT available in Canada or the USA.'
            }

        },
        computed: {
            ...mapState({
                channelSettings: state => state.channel.settings,
            }),
            getContactTitle() {
                return this.channelSettings.content['contact-title']
            },
            getContactText() {
                return this.convertEditorJsonToHtml(this.channelSettings.content['contact-text']);
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/css/fonts";
    .contact .container{
        font-family: $font-main;
        font-style: italic;
        font-weight: 400;
    }
    .contact-title {
        font-size: 1.5em;
        padding: 2em 0;
        background-color: rgba(245, 245, 245, 100);
        font-weight: bold;
        text-transform: uppercase;
    }

    .contact-logo {
        margin-top: 2em;
        height: 5em;
    }

    h1 {
        font-size: 2em;
        font-weight: bold;
        text-transform: uppercase;
        margin: 2em auto 0;

        &:after {
            content: '';
            display: block;
            width: 2%;
            min-width: 50px;
            height: 2px;
            background-color: black;
            margin: 0.5em auto;
        }
    }

    .contact-text {
        margin: 3em 0;
        text-align: left;
        word-break: break-word;
        font-size: 1rem;
    }
</style>
